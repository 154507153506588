<template>
    <div>
        <doctors-component :unique="true"/>
        <footer-component />
    </div>
</template>

<script>
import DoctorsComponent from "@/components/medical-services/DoctorsComponent";
import FooterComponent from "@/layouts/app-layout/footer/FooterComponent";
import { changeInformationSEO } from "@/providers/KeywordsServices";

export default {
    name: "CertificateDoctor",
    title: "Specialist doctors | Baja California Health Tourism",
    components: {
        DoctorsComponent,
        FooterComponent,
    },
    mounted() {
        changeInformationSEO();
    }
};
</script>

<style scoped></style>
